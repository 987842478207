/* You can add global styles to this file, and also import other style files */
@import url('../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css');
@import url('../node_modules/primeng/resources/primeng.min.css');
@import url('../node_modules/primeicons/primeicons.css');
@import url('./assets/styles/primeng-override.scss');
@import url('../node_modules/quill/dist/quill.core.css');
@import url('../node_modules/quill/dist/quill.snow.css');

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.font-18 {
    font-size: 18px;
}

.font-24 {
    font-size: 24px;
}

.cursor-pointer {
    cursor: pointer;
}

.w100 {
    width: 100%;
}

.w70 {
    width: 70%;
}

.w20 {
    width: 20%;
}

.w80 {
    width: 80%;
}

.w50 {
    width: 50%;
}

.w30 {
    width: 30%;
}

.w15 {
    width: 15%;
}

.w85 {
    width: 85%;
}

.m5 {
    margin: 5px;
}

.pl-30 {
    padding-left: 30px;
}

.p20 {
    padding: 20px;
}

.p5 {
    padding: 5px;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.center {
    margin: 0 auto;
}

.login-box {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 10%;
}

.textInput {
    padding-left: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0%;
}

.btn-blue {
    transition: 0.3s;
    background-color: #002b5c;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.savebtn {
    transition: 0.3s;
    background-color: #32d032;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.editbtn {
    transition: 0.3s;
    background-color: #e5b116;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.btn-blue:hover {
    background: #00418c;
}

.top-header {
    background-color: #002B5C;
    color: #fff;
    padding: 20px 30px 10px 30px;
    font-size: 22px;
    position: absolute;
    z-index: 10;
    width: 100%
}

.m10 {
    margin: 10px;
}

.mt10 {
    margin-top: 10px;
}

.ml10 {
    margin-left: 10px;
}

.btn-red {
    background-color: rgb(231, 31, 31);
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.btn-red:hover {
    background-color: red;
}

/*If interested*/
.btn-green {
    background-color: #0e8f23;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.d-flex {
    display: flex;
}

.py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.w40p {
    width: 40px;
}

.pt12 {
    padding-top: 12px;
}

.pr {
    position: relative;
}

.m0 {
    margin: 0;
}

.p25 {
    padding: 25px;
}

.light-grey-bg {
    background-color: #f9f9f9;
}

.mtb-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.pr25 {
    padding-right: 25px;
}

.heading {
    font-size: 22px;
    padding: 10px 15px;
}

.mlr-18 {
    margin-left: 18px;
    margin-right: 18px;
}

.mr-15 {
    margin-right: 15px;
}

.padd0 {
    padding: 0;
}

.txtlink:hover {
    text-decoration: underline;
    color: #00418c;
}

.red {
    color: red;
    font-weight: bold;
}

.formCard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px;
    background-color: #ffffff;
    overflow-y: hidden;
    margin-top: 5px;
}

.dropdown {
    margin: 0px;
    width: 100%;
    border-radius: 5px;
    padding: 5px 35px 5px 5px;
    font-size: 16px;
    border: 1px solid #CCC;
    height: 35px;
}

.h-30 {
    height: 30px
}

.mr5 {
    margin-right: 5px;
}

.inline-block {
    display: inline-block;
}

.info-icon {
    color: red;
    font-size: 12px;
}

.ml-30 {
    margin-left: 30px;
}

.maxw-80 {
    max-width: 80px;
}

#bar {
    .p-progressbar {
        height: 20px;
        background: #32d03288;
        border-radius: 10px;
    }

    .p-progressbar-determinate .p-progressbar-label {
        padding: 8px;
    }

    .p-progressbar .p-progressbar-value {
        background: #32d032;
    }

}