.p-sidebar-left {
    background-color: #002b5c;
}

button.p-sidebar-close.p-sidebar-icon.p-link {
    color: #fff;
}

.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-thead>tr>th {
    padding: 0.3rem;
    border-width: 1px;
    overflow-wrap: anywhere;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: center;
    background: #e4e4e4;
}

#description .ql-image {
    display: none;
}

.trail .p-dropdown.p-component {
    width: 100%;
}

.trail span.p-dropdown-label.p-inputtext {
    padding: 2px 5px;
}

span.p-calendar {
    width: 100%;
}

.p-chips {
    display: block !important;
}

#points .p-editor-toolbar {
    max-height: 100px;
}